import React from 'react'
import { Container, Typography} from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo'

const AddOns = () => {
    return (
        <Layout location="">
            <SEO title='Add Ons' description='blix getting to know our add ons' />
            <Container fixed style={{paddingTop: '150px', paddingBottom: '50px'}}>
                <Typography color='textPrimary'>Terms of Service</Typography>
                <Typography color='textSecondary'>
                    Effective Date: May 30th, 2018
                    <br/>
                    Blix service (“Service”) is provided by Blix Inc. (“Blix”, “we”, “our”). By using our Service, you are agreeing to these Terms of Service (“Terms”) and certify that you and your Users are all over the age of 13. Blix is a team-based communication service.
                    <br/>
                    When registering for Blix, the account Administrator will represent the organization or entity as Blix’s Customer (“Customer”). If your team is not formally affiliated with any organizations or entities, the account Administrator will represent your team as Blix’s Customer. “You” and “your” refers to the Customer. “User” refers to the member who is invited to the Customer’s Blix account.
                    <br/>
                    Please read these terms carefully before starting to use our Service.
                    <br/>
                    Privacy Protection
                    <br/>
                    Our Privacy Policy explains how we treat your personal data and protect your privacy when using our Service. By using our Service, you automatically agree to our privacy policies, and Blix can use such data in accordance with its privacy policies.
                    <br/>
                    Your Data in Our Service
                    <br/>
                    Our service allows you to upload, download, and store content, including but not limited to, information, text, graphics, videos, audios, or other material (“Data”). You retain ownership of any intellectual property rights that you hold in that Data. When you upload, store, send, or receive Data through our Service, you give Blix and its service partners a worldwide license to host, store, upload, and download this Content only for the purpose of providing the Service strictly to you. We reserve our right to remove or refuse to distribute any Data through the Service, including your Data, at any time. However, we are never obligated to do so. For more detailed information regarding Data privacy, please read our Privacy Policy.
                    <br/>
                    Software in Our Service
                    <br/>
                    When the Service requires or includes downloadable software, this software may update automatically on your device once a new version or features become available to you. Some platforms may let you adjust your automatic update settings.
                    <br/>
                    Blix gives you a personal, worldwide, royalty-free, non-assignable, and non-exclusive license to use the software provided by Blix as part of the Service. You may not copy, modify, distribute, sell, or lease any part of our Service or included software, nor may you reverse engineer or attempt to extract the source code of the software, unless local laws prohibit those restrictions or you have our written permission.
                    <br/>
                    Modifying and Terminating Our Service
                    <br/>
                    Blix may add or remove functionalities or features in the normal course of improving, changing, and/or updating the Service. We may also suspend or stop our Service with at least thirty (30) days notice prior to the complete shutdown of our Service.
                    <br/>
                    You can choose to stop using our Service at will. We may also stop providing Service to you, or add or create new limitations to our Service at any time as detailed in the Terms.
                    <br/>
                    API
                    <br/>
                    You may access your Data stored in the Service via the Application Program Interface (“API”). By using the API, you are automatically bound by the Terms.
                    <br/>
                    Using Our Service
                    <br/>
                    You must follow any policies made available to you within the Service. You may only use our Service as permitted by law. Blix may investigate and/or suspend or terminate our Service to you at any time if we find your use of our Service violates the Terms and/or any policies.
                    <br/>
                    Using our Service does not grant you ownership of any intellectual property rights in our Service or the content you may have access to. You may not use any copyrighted content in our Service unless you obtain permission from the content owner and/or are otherwise permitted by law. The Terms do not grant you the right to use any branding or logos used in our Service. Our Service may display some logos, trademarks, or branding materials that are not the property of Blix. These types of content are the sole responsibility of the entity that makes them available.
                    <br/>
                    You must not abuse and/or misuse our Service including, but not limited to, doing the following things:
                    <br/>
                    Using the Service for any unlawful purposes or activities;
                    Uploading any content to the Service in violation of any applicable law, including but not limited to, intellectual property laws and publicity laws;
                    Sending unsolicited promotions or advertisements;
                    Accessing or tampering with the Service’s server systems;
                    Interfering with or disrupting the access of any user, host, or network;
                    Abusing or submitting excessively frequent requests to the Service via the API.
                    Blix, at its sole discretion, will determine abuse and/or misuse of our Service.
                    <br/>
                    Limited Liability for Our Service
                    <br/>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICE IS AVAILABLE “AS IS”. YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
                    <br/>
                    WHEN PERMITTED BY LAW, BLIX AND ITS SERVICE PARTNERS, LICENSORS, EMPLOYEES, AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES UNDER ANY CIRCUMSTANCES.
                    <br/>
                    YOUR USE AND/OR PURCHASE OF THE SERVICE ARE ALL AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” BASIS.
                    <br/>
                    BLIX DOES NOT WARRANT THAT:
                    <br/>
                    THE SERVICE WILL MEET ALL OF YOUR REQUIREMENTS AT ALL TIMES;
                    THE SERVICE WILL BE ERROR-FREE AND ALL ERRORS IN THE SERVICE WILL BE CORRECTED;
                    THE SERVICE WILL BE UNINTERRUPTED.
                    ALL CONTENT DOWNLOADED, UPLOADED AND/OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER EQUIPMENT OR DEVICES, INCLUDING BUT NOT LIMITED TO, LAPTOP COMPUTERS, DESKTOP COMPUTER, TABLETS, SMARTPHONES AND SMARTWATCHES, OR ANY DATA LOSS RESULTING FROM DOWNLOAD OR USE OF ANY SUCH ABOVE MENTIONED MATERIAL.
                    <br/>
                    Information Gathering and Usage
                    <br/>
                    When registering for Blix, we ask for information such as your name, email address. Your information is only used internally and isn’t shared with others. More details can be found in our Privacy Policy.
                    <br/>
                    Change of Terms of Service
                    <br/>
                    From time to time, Blix may modify existing terms and/or update these terms with additional terms that apply to the Service. For example, Blix may modify existing terms to reflect any mandatory changes to the law. You should check the terms regularly to keep informed. Blix will also post notice of modification to these Terms on this page or via the email address you registered with us. Note, changes will not apply retroactively and will become effective thirty days after they are posted on our website. Changes addressing new functions for the Service or changes made for legal reasons may be effective immediately. You should discontinue your use of our Service if you do not agree with the updated Terms.
                </Typography>
            </Container>
        </Layout>
    );
}

export default AddOns